#hero{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
}

#waves{
    position: relative;
    /* relative to hero */
    width: 100%;
    height: 60vh;
    top: 10vh;
    overflow: hidden;
}

div.wave {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("/public/images/tape.png");
    background-size: 100% 60%;
    background-repeat: repeat-x;
}

.tape1{
    animation: wave 23s linear alternate infinite;
    z-index: 4;
    opacity: 0.1;
    animation-delay: 0;
}

.tape2 {
    animation: wave2 29s linear alternate-reverse infinite;
    z-index: 3;
    opacity: 0.3;
    animation-delay: -3;
    top: 5vmin;
}

.tape3 {
    animation: wave 19s linear alternate infinite;
    z-index: 2;
    opacity: 0.3;
    animation-delay: -2s;
    top: 10vmin;
}

.tape4 {
    animation: wave2 37s linear alternate-reverse infinite;
    z-index: 1;
    opacity: 0.2;
    animation-delay: -1s;
    top: 15vmin;
}

@keyframes wave{
    0%
    {
        background-position-x: 0;
    }
    100%
    {
        background-position-x: 100vmin;
    }
}

@keyframes wave2 {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: -100vmin;
    }
}

#hero-headers{
    position: relative;
    z-index: 5;
    height: fit-content;
    bottom: 40vh;
}

#hero-headers h1{
    font-family: "Birthstone", cursive;
    color: white;
    text-shadow: 1px 1px 1px #000;
    font-weight: 600;
    font-size: 11vmin;
    margin: 0;
}

#hero-headers h3 {
    font-family: "Birthstone", cursive;
    color: white;
    text-shadow: 1px 1px 1px #000;
    font-size: 6vmin;
    margin: 0;
}

#hero-comment{
    position: relative;
    max-width: 80%;
    height: fit-content;
    color: #646464;
    font-family: "DM Serif Text", serif;
    font-size: 90%;
    margin: 0;
    z-index: 5;
    bottom: 20vh;
}

#about{
    position: relative;
    width: 100%;
    max-height: 80vh;
    display: flex;
}

#profile-grid{
    width: 100%;
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 5%;
    align-items: center;
}

.profile-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#introduction {
    height: fit-content;
    font-family: "DM Serif Text", serif;
    margin: 0;
    padding-bottom: 0.5rem;
    border-bottom: 5px double rgba(255, 128, 128, 0.4);
}

.profile-item > #profile-img{
    background-image: url("/public/images/profile-pic.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 90%;
    aspect-ratio: 3 / 4;
    border-radius: 64% 36% 50% 50% / 68% 62% 38% 32%;
    animation: rippleEdge 2.4s linear alternate infinite;
}

.profile-item > #profile-img:hover {
    cursor: pointer;
    border-radius: 55% 45% 50% 50% / 51% 44% 56% 49%;
}

/* top right: inner to outer */
/* bottom left: inner to outer */
/* x y blur spread */

@keyframes rippleEdge {
    0% {
        box-shadow:
            rgb(255, 255, 255) 6px -7px,
            rgb(255, 0, 0, 0.3) 8px -8px 0 2px,

            rgb(255, 255, 255) 18px -17px,
            rgb(255, 0, 0, 0.6) 19px -19px 0 2px,

            rgb(255, 255, 255) -12px 12px,
            rgb(255, 0, 0, 0.2) -15px 15px 0 2px,

            rgb(255, 255, 255) -12px 12px,
            rgb(255, 0, 0, 0.2) -15px 15px 0 2px;
    }

    100% {
        box-shadow:
            rgb(255, 255, 255) 11px -11px,
            rgb(255, 0, 0, 0.2) 13px -13px 0 2px,

            rgb(255, 255, 255) 11px -11px,
            rgb(255, 0, 0, 0.2) 13px -13px 0 2px,

            rgb(255, 255, 255) -7px 8px,
            rgb(255, 0, 0, 0.3) -9px 9px 0 2px,

            rgb(255, 255, 255) -19px 18px,
            rgb(255, 0, 0, 0.6) -20px 20px 0 2px;
    }
}

#hero-button{
    position: relative;
    padding: 2px 6px;
    bottom: 35vh;
    z-index: 5;
    border-radius: 10px;
    box-shadow: rgba(120, 0, 0, 0.2) 0px 2px 8px 0px;
    background: linear-gradient(135deg, rgba(145, 152, 229, 0.7), rgba(255, 255, 255, 0) 70.71%),
                linear-gradient(270deg, rgba(255, 150, 150, 0.8), rgba(255, 255, 255, 0) 70.71%);
}

#hero-button a{
    text-decoration: none;
}

#hero-button:active {
    transform: scale(1.3);
    transition: all 0.1s;
}

#hero-button a span  {
    color: white;
    font-family: "DM Serif Text", serif;
    font-weight: 600;
    font-size: 1.2rem;
    text-wrap: nowrap;
}

@media screen and (max-width: 660px) {
    #hero-headers h1, #hero-headers h3{
        text-shadow: 0.8px 0.8px 0px #000;
    }
}

/* Example ratios:
iPhone SE 375/667 = 0.56
iPhone XR 414/896 = 0.46
iPhone 14 430/932 = 0.46
Pixel 7 412/915 = 0.45
Surface Duo 540/720 = 0.75 */

/* for vertical mobile screens */
@media screen and (max-aspect-ratio: 1/1) {
    #profile-grid {
        grid-template-columns: 1fr 1fr;
        column-gap: 2rem;
        row-gap: 0;
    }

    #introduction {
        font-size: small;
    }

    #timeline-wrapper {
        grid-column: 1/ span 2;
    }
}

/* for horizontal mobile screens */
@media screen and (min-aspect-ratio: 1/1) and (height <= 440px) {
    #about{
        height: 75vh;
    }

    #hero-comment {
        font-size: small;
        bottom: 25vh;
    }
    
    #introduction{
        font-size: x-small;
    }
}

.dropdown-menu {
    background: linear-gradient(to right, rgb(255, 100, 100, 0.3) 0%, rgb(115, 0, 255, 0.15) 100%);
    position: absolute;
    top: 100%;
    min-width: 0;
    width: 60%;;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 0;
    border-radius: 5px;
    list-style: none;
    animation: 0.4s rollout;
}

@keyframes rollout {
    0% {
        transform: translateY(-30%);
    }

    100% {
        transform: translateY(0);
    }
}

.dropdown-menu li {
    position: relative;
    cursor: pointer;
    padding: 5% 5%;
    height: 2.6rem;
    align-content: center;
    border-radius: inherit;
}

/* blurry background enabled for supported devices */
@supports(backdrop-filter: blur(2px)) {
    .dropdown-menu {
        backdrop-filter: blur(2px);
    }
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    font-size: 120%;
    text-decoration: none;
    color: white;
    padding: 0 6px;
}

.dropdown-link:hover{
    color: rgb(0, 0, 0, 0.9);
}
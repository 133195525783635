#timeline{
    position: relative;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.event{
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 1vmin;
    padding: 5% 0;
}

.event:nth-child(1){
    animation-delay: 0;
}
.event:nth-child(2) {
    animation-delay: 1s;
}

.txt-box{
    position: relative;
    border-radius: 1vmin;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-size: 90%;
    font-family: "DM Serif Text", serif;
    width: 100%;
}

.txt-box > p{
    padding: 1vmin;
    margin: auto;
}

.txt-box .institute{
    border-bottom: 1px solid rgb(200, 200, 200);
}

.txt-box .caption{
    font-style: italic;
    font-size: 70%;
}

.txt-box::before{
    content: "";
    position: absolute;
    width: 40%;
    border: 1px dashed rgb(100, 100, 100);
    right: 100%;
    top: 50%;
    z-index: 1;
}

#axis{
    position: absolute;
    top: 8%;
    border-left: 2px dashed rgb(100, 100, 100);
    left: 52px
}

.logo{
    position: relative;
    display: flex;
    align-items: center;
    margin: auto 20% auto 7px;
    z-index: 2;
}

.logo img{
    position: relative;
    width: 2.5rem;
    border-radius: 50%;
    background-color: white;
}

.year {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 80%;
    font-family: "DM Serif Text", serif;
    top: 25%;
}


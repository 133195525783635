.navbar {
    /* layers: top to bottom */
    background: linear-gradient(90deg, rgb(255, 255, 255, 1), rgb(255,255, 255, 0.2) 60%),
                linear-gradient(270deg, rgb(255, 255, 255, 0.9), rgb(255, 0, 0, 0.3) 40%),
                linear-gradient(270deg, rgb(115, 0, 255, 0.6), rgb(255, 255, 255, 0.2) 40%);
    position: fixed;
    width: 100%;
    height: 10vh;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "title menu";
    align-items: center;
    padding: 0;
    z-index: 6;
}

/* blurry background enabled for supported devices */
@supports(backdrop-filter: blur(2px)){
    .navbar{
        backdrop-filter: blur(2px);
    }
}

#section-title{
    position: relative;
    left: 15%;
    width: fit-content;
    font-size: 7vh;
    font-family: "Birthstone", cursive;
    color: #000;
}

.nav-menu {
    position: relative;
    left: 25%;
    display: flex;
    list-style: none;
    min-height: 0;
    margin: 0;
    padding: 0;
}

.nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4vh 4rem;
    /* this margin affects the clickability of dropdown */
    height: 100%;
    font-family: "DM Serif Text", serif;
    color: #fff;
    /* setting font color here but not in .nav-link, see below for explanation */
}

.nav-link {
    position: relative;
    /* if color set (only) here, clicking will change color to black */
    font-size: 140%;
    text-decoration: none;
    color: #fff;
}

.nav-link.active span{
    text-shadow: rgb(255, 128, 128, 0.8) 0px 6px 10px;
}

.nav-link:hover {
    color: rgb(0, 0, 0, 0.9);
}

.fa-angle-down {
    transition: all 0.2s ease-in-out;
    margin: 0 4px;
}

.fa-angle-down.rotate{
    rotate: -180deg;
    transition: all 0.2s ease-in-out;
}

.fa-bars {
    color: #fff;
}

.menu-icon{
    width: fit-content;
}

/* navbar menu icon on mobile devices not displayed on desktop */
.menu-icon {
    display: none;
    left: 70%;
}

/* for vertical and horizontal mobile screens */
@media screen and (max-aspect-ratio: 1/1),
       screen and (min-aspect-ratio: 1/1) and (height <= 440px)
{
    .menu-icon {
        display: block;
        position: relative;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-bars {
        transition: all 0.1s ease-out;
    }

    .fa-xmark {
        color: #fff;
        font-size: 2rem;
        transition: all 0.1s ease-out;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 100%;
        background: linear-gradient(90deg, rgb(255, 255, 255, 1), rgb(255, 255, 255, 0.2) 60%),
                linear-gradient(270deg, rgb(255, 255, 255, 0.9), rgb(255, 0, 0, 0.3) 40%),
                linear-gradient(270deg, rgb(115, 0, 255, 0.6), rgb(255, 255, 255, 0.2) 40%);
        /* must include background
        otherwise only link texts not the background will show during transition */
        transition: all 0.4s ease;
    }

    .nav-menu.active {
        left: 0;
        transition: all 0.4s ease;
    }

    .nav-item{
        padding: 10px 0;
    }

    /* no dropdown menu indicator on mobile devices */
    .fa-angle-down {
        display: none;
    }

}

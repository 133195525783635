#footer{
    position: relative;
    display: grid;
    gap: 6px;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
    margin-top: 0.5rem;
    background: linear-gradient(270deg, rgb(255, 255, 255, 1), rgb(255, 255, 255, 0.1) 60%),
            linear-gradient(90deg, rgb(255, 255, 255, 0.9), rgb(255, 0, 0, 0.1) 40%),
            linear-gradient(90deg, rgb(115, 0, 255, 0.1), rgb(255, 255, 255, 0.1) 40%);
}

.contact{
    grid-column: span 1;
    text-align: center;
}

.contact a{
    text-decoration: none;
    font-size: 1.8rem;
    padding: 0 1rem;
    /* color: rgb(255, 255, 255); */
    color: rgb(0, 0, 0, 0.8);
    text-shadow: 1px 1px 3px rgb(0, 0, 0, 0.3);
    /* text-shadow: 1px 1px 3px rgb(0, 0, 0, 0.3), -1px -1px 3px rgb(0, 0, 0, 0.1); */
}

#copyright{
    margin: 0;
    grid-column: span 1;
    /* color: rgb(255, 255, 255); */
    color: rgb(0, 0, 0, 0.5);
    font-size: small;
    /* text-shadow: 1px 1px 2px rgb(0, 0, 0, 0.5), -1px -1px 2px rgb(0, 0, 0, 0.1); */
    text-align: center;
    font-family: "DM Serif Text", serif;
}


@media screen and (max-width: 600px) {
    #footer{
        grid-template-columns: repeat(1, 1fr);
    }

    .contact{
        grid-row: 1 / span 1;
    }
}
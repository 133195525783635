#carousel{
    position: relative;
    width: 100%;
    overflow: hidden;
}

#carousel-wrapper{
    display: flex;
    align-items: center;
    cursor: grab;
    height: 100%;
    width: 100%;
}

#carousel-wrapper:active{
    cursor: grabbing;
}

.carousel-overlay{
    position: relative;
    width: 32%;
    top: 15%;
    left: 6%;
    font-family: "DM Serif Text", serif;
    background-image: linear-gradient(to top, rgb(196, 197, 199, 0.3), rgb(220, 221, 223, 0.2), rgb(235, 235, 235, 0.1) 100%);
    border-radius: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* box-shadow: rgba(64, 64, 64, 0.1) 0px 4px 12px; */
}

.carousel-overlay h3{
    letter-spacing: 1px;
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 6% 6% 2% 6%;
    font-size: 3.2vh;
}

.carousel-overlay h1{
    letter-spacing: 3px;
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 0 6%;
    font-size: 5vh;
}

.carousel-overlay p{
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 2% 6% 6% 6%;
    text-shadow: 1px 1px 2px rgb(64, 64, 64, 0.3);
}

.carousel-card{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    /* use below config with scales 0.95, 0.85 */
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#carousel-dots{
    display: flex;
    position: absolute;
    bottom: 5%;
    width: 100%;
    min-height: 0;
    justify-content: center;
    gap: 0.5rem;
}

#carousel-dots button{
    /* mysterious padding would prevent perfect circles in liminted space */
    padding: 0;
    width: 0.75rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border-width: 0;
    cursor: pointer;
    background-color: rgb(115, 115, 115, 0.8);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#carousel-dots button:active{
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

#carousel-dots button.selected {
    background-color: rgb(255, 255, 255, 0.8);
}

/* .left-edge{
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    width: 10vw;
    max-width: 100px;
    background: linear-gradient(90deg, rgb(10, 10, 10, 0.2), rgb(10, 10, 10, 0));
}

.right-edge {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    width: 10vw;
    max-width: 100px;
    background: linear-gradient(270deg, rgb(10, 10, 10, 0.2), rgb(10, 10, 10, 0));
} */

/* for vertical mobile screens */
@media screen and (max-aspect-ratio: 1/1) {
    .carousel-overlay {
        left: 5%;
        width: 90%;
    }
}

/* for horizontal mobile screens */
@media screen and (min-aspect-ratio: 1/1) and (height <= 550px){
    .carousel-overlay{
        top: 8%;
        left: 4%;
        width: 48%;
        height: fit-content;
    }

    .carousel-overlay p{
        font-size: 13px;
    }
}

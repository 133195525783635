#photography{
    display: flex;
    position: relative;
    width: 100%;
}

#soccer {
    position: relative;
    display: flex;
    width: 100%;
    height: 80vh;
    align-items: center;
    justify-content: center;
}

.grid-wrapper{
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 5%;
    height: 100%;
    align-items: center;
}

.grid-item{
    position: relative;
    display: flex;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.grid-item video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    z-index: 1;
}

#video-comment{
    position: absolute;
    display: flex;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: rgb(0, 0, 0, 0.5);
    opacity: 0;
}

#video-comment.show{
    opacity: 1;
    z-index: 2;
    transition: all 0.5s ease-in-out;
}

#video-comment p{
    font-family: "DM Serif Text", serif;
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 0 8%;
}

/* for vertical mobile screens */
@media screen and (max-aspect-ratio: 1/1) {        
    #soccer{
        height: 85vh;
    }

    .grid-wrapper{
        grid-template-columns: 1fr;
        gap: 0;
    }

    .grid-item {
        height: 90%;
        width: fit-content;
    }
}

@media screen and (height <= 600px){
    #soccer{
        height: 80vh;
    }

    #video-comment p {
        font-size: small;
    }
}

/* for horizontal mobile screens */
@media screen and (min-aspect-ratio: 1/1) and (height <=440px){
    #soccer{
        height: 70vh;
        padding-top: 15vh;
    }

    .grid-item {
        height: 100%;
    }
}
